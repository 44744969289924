export default {
    meta_title: 'Research — PennyLane',
    meta_description:
      'PennyLane is more than a quantum software library — it’s a complete ecosystem of resources built for research, by researchers. Discover how to accelerate your work!',
    meta_image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/research/open_graph.png',
  
    /* Hero Section */
    heroSection:{
      /* Hero Main */
      title: 'Built by researchers, for research.',
      description:
        'More than just a software library — PennyLane is a complete ecosystem of resources for quantum researchers. Accelerate your work by building on existing discoveries and unlocking new opportunities.',
      image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/research/hero.png',
      ctas: {
        /* Appears as a button */
        primary: {
          text: 'Using PennyLane',
          link: 'https://docs.pennylane.ai/en/stable/introduction/pennylane.html',
        },
        /* Appears as a cta style link "text ->"*/
        secondary: {
          text: 'Learn how to showcase your research',
          link: '/get-involved',
        },
      },
    },

    /* Research Papers */
    researchPapers: {
        title: `Research papers using PennyLane`,
        description: `Discover how researchers are using PennyLane to advance quantum computing innovation. [See what they're saying!](#researcher-testimonials)`,
        papers: [
            {
                description: `Joseph Bowles, Shahnawaz Ahmed, and Maria Schuld use PennyLane with Scikit-Learn to discuss the importance of **benchmarking in quantum model design.**`,
                callToAction: [
                    {
                        label: `View paper`,
                        link: `https://arxiv.org/abs/2403.07059`,
                        icon: `bx-link-external`
                    },
                    {
                        label: `See blog post`,
                        link: `/blog/2024/03/benchmarking-near-term-quantum-algorithms-is-an-art`,
                        icon: `bxs-chevron-right`,
                        isAnimated: true
                    }
                ]
            },
            {
                description: `See how PennyLane is used by researchers at Freie Universität Berlin to extract **classical surrogates from quantum machine learning models**, which efficiently reproduce the models’ input-output relations.`,
                callToAction: [
                    {
                        label: `View paper`,
                        link: `https://arxiv.org/abs/2206.11740`,
                        icon: `bx-link-external`
                    }
                ]
            },
            {
                description: `Quantum chemistry is a key area for quantum computing. Research teams at Covestro and QC Ware used PennyLane to show that **Regularized Compressed Double Factorization** can significantly **reduce measurement bases and shot count in chemistry applications.**`,
                callToAction: [
                    {
                        label: `View paper`,
                        link: `https://quantum-journal.org/papers/q-2024-06-13-1371/`,
                        icon: `bx-link-external`
                    }
                ]
            },
            {
                description: `With a fast iteration between software feature development and research, the Xanadu algorithms team developed a **differentiable Hartree-Fock solver in PennyLane,** which enabled new research in quantum machine learning for molecular geometry optimization.`,
                callToAction: [
                    {
                        label: `View paper`,
                        link: `https://arxiv.org/abs/2106.13840`,
                        icon: `bx-link-external`
                    },
                    {
                        label: `See demo`,
                        link: `/qml/demos/tutorial_differentiable_HF`,
                        icon: `bxs-chevron-right`,
                        isAnimated: true
                    }
                ]
            }
        ]
    },
    /* Demos Section */
    featuredDemos: {
        title: 'The most comprehensive research demo library',
        description: 'Explore our expertly crafted research demos, all based on published papers, bringing cutting-edge studies to life.',
        demos: ["tutorial_shadow_hamiltonian_simulation", "tutorial_classical_expval_estimation", "tutorial_haar_measure"],
        callToAction: {
            label: 'See all demos based on papers',
            link: '/search/?contentType=DEMO&paper=true&sort=publication_date'
        }
    },
    /* Submit your ideas form */
    submitForm: {
        title: 'Impactful research drives PennyLane',
        description: 'Submit your feature ideas to tailor our roadmap to your research needs.',
        footnote: `By submitting, you are agreeing to our [privacy policy](/privacy).`,
        formSubmittedTitle: 'Thank you!',
        formSubmittedText: `A PennyLane team member will be in touch with you shortly. In the meantime, explore how you can [get involved](https://pennylane.ai/get-involved).`,
        formErrorMessage: 'Something went wrong. Please try again later.',
    },
    /* Datasets collections */
    datasetCollections: {
        title: 'Speed up research with Quantum Data',
        description: 'Download and share impactful datasets that are tailored to use with PennyLane.',
        callToAction: {
            label: 'Discover more datasets',
            link: '/datasets'
        }
    },
    /* Researcher Testimonials */
    testimonials_section: {
        title: 'Groups using PennyLane for research',
        quotes: [
             {
                quote:
                'PennyLane is central to our quantum computing research. We value the stable user interface and automatic differentiation, in combination with the unmatched flexibility of defining custom operations and interface our own simulators, while also having the option of running on real quantum hardware.',
                author: {
                name: 'Christian Gogolin',
                affiliation: 'Head of High Performance & Quantum Computing, Digital Research and Development | COVESTRO',
                image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/research/testimonials/christian_gogolin.jpg',
                },
            },
            {
                quote:
                'PennyLane is our go-to framework for developing and testing quantum algorithms. I also use it in my undergrad quantum computing class. A major reason is the availability of good documentation. The example usage for library functions, demos, and how-to guides are useful for both me and the students.',
                author: {
                name: 'Olivia Di Matteo',
                affiliation: 'Assistant Professor, Faculty of Applied Science, Department of Electrical and Computer Engineering | UNIVERSITY OF BRITISH COLUMBIA',
                image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/research/testimonials/olivia_di_matteo.png',
                },
            },
            {
                quote:
                `It is an exhilarating experience to spend months in deep technical theory on quantum machine learning algorithms, only to realise that we can probe some of our ideas already using PennyLane. Seeing the numbers confirm our intuition—or, even better, contradict it—always feels a little like magic.`,
                author: {
                name: 'Maria Schuld',
                affiliation: 'QML Team Lead | XANADU QUANTUM TECHNOLOGIES INC.',
                image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/research/testimonials/maria_schuld.png',
                },
            }, 
            {
                quote:`Part of our “secret sauce” at Xanadu is that we're developers and power users of PennyLane. The process isn't easy, but the results are powerful: we've built a tool that accelerates our quantum algorithms research through rapid prototyping, fast simulation, and flexible resource estimation.`,
                author: {
                    name: 'Juan Miguel Arrazola',
                    affiliation: 'Head of Algorithms | XANADU QUANTUM TECHNOLOGIES INC.',
                    image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/research/testimonials/juan_miguel_arrazola_mantilla.jpg',
                },
            },
        ],
    },
}
