import { useMemo } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { spotlights } from 'shared-content'
import { sendCustomGAEvent } from 'shared-utilities'
import { PageProps, graphql } from 'gatsby'
import SpotlightSection, {
  IAnnouncement,
} from '../components/SpotlightSection/SpotlightSection'
import SubSectionLayout from '../components/SubSectionLayout/SubSectionLayout'
import Seo from '../components/seo'
import featuresContent from '../content/features'
import SliderSection from '../components/SliderSection/SliderSection'
import ProcessSection from '../components/ProcessSection/ProcessSection'
import IconRowSection from '../components/IconRowSection/IconRowSection'
import ResearchContact from '../components/ResearchContact/ResearchContact'
import '../scss/pages/features.scss'

const {
  meta_title,
  meta_description,
  meta_image,
  hero,
  newest_features,
  pennylane_features,
  research,
  sub_section,
} = featuresContent
const spotlight = spotlights.features_page
const HERO_BACKGROUND = `https://assets.cloud.pennylane.ai/pennylane_website/backgrounds/confetti_transparent.png`

const Features = ({ data }: PageProps<Queries.FeaturePageFeaturesQuery>) => {
  const latestFeaturesNode =
    data.allMarkdownRemark.nodes.find((node) => node.frontmatter?.features) ||
    null

  const latestFeatures = useMemo(() => {
    return latestFeaturesNode?.frontmatter?.features || []
  }, [latestFeaturesNode])

  return (
    <>
      <div className="Hero__Background__Container">
        <StaticImage
          src={HERO_BACKGROUND}
          alt=""
          loading="eager"
          placeholder="none"
          quality={100}
          objectPosition="top left"
          className="Hero__Background__Image Sticky__Image"
        />
        <section className="FeaturesHero">
          <SubSectionLayout
            image_right_aligned={true}
            title={hero.title}
            description={hero.description}
            image={hero.image}
            button_link={hero.button_link.url}
            button_text={hero.button_link.text}
            button_onClick={() =>
              sendCustomGAEvent('click', {
                event_category: 'features',
                event_label: 'install',
                value: hero.button_link.url,
              })
            }
            links={[
              {
                link_text: hero.text_link.text,
                link: hero.text_link.url,
                gaEvent: {
                  event_category: 'features',
                  event_label: hero.text_link.text,
                  value: hero.text_link.url,
                },
              },
            ]}
          />
        </section>
        {!spotlight.hidden && (
          <SpotlightSection
            announcements={spotlight.announcements.map(
              (announcement: IAnnouncement) => {
                return {
                  ...announcement,
                  links: announcement.links.map((link) => {
                    return {
                      ...link,
                      gaEvent: {
                        event_category: 'features',
                        event_label: link.link_text,
                        value: link.link,
                      },
                    }
                  }),
                }
              }
            )}
          />
        )}
      </div>
      <IconRowSection
        title={research.title}
        subtitle={research.subtitle}
        icons={research.icons}
        cta_link={research.cta_link}
      />
      {latestFeatures.length > 0 && (
        <div className="NewestFeatures">
          <SliderSection
            className="NewestFeatures__Slider"
            title={newest_features.title}
            slides={latestFeatures.map((feature) => {
              return {
                description: feature?.description || '',
                logo: feature?.image || '',
                logoAltText: feature?.description || '',
                link: feature?.link || '',
                gaEvent: feature
                  ? {
                      event_category: 'features',
                      event_label: String(feature.description),
                      value: String(feature.link),
                    }
                  : undefined,
              }
            })}
          />
        </div>
      )}
      <div className="PennyLaneFeatures">
        <ProcessSection
          id={pennylane_features?.id}
          title={pennylane_features.title}
          steps={pennylane_features.steps}
          gaEventCategory="features"
        />
      </div>
      <ResearchContact pageName={meta_title} />
      <div className="Features__subsection-layout">
        <SubSectionLayout
          title={sub_section.title}
          description={sub_section.description}
          image={sub_section.image}
          links={sub_section.links}
        />
      </div>
    </>
  )
}

export default Features

export const Head = () => {
  return (
    <Seo title={meta_title} description={meta_description} image={meta_image} />
  )
}

export const pageQuerys = graphql`
  query FeaturePageFeatures {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/blog/" }
        frontmatter: { category: { eq: "Releases" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        frontmatter {
          features {
            link
            image
            description
          }
        }
      }
    }
  }
`
